<template>
  <vue-gauge v-if="loaded"
             :refid="id"
             :options="option" />
</template>
<script>
import HTTP from "../../../Api/auth"
import VueGauge from "vue-gauge"
// import { mapActions, mapGetters } from "vuex"
export default {
  name: "GaugeMcfs",
  data: () => ({
    loaded: false,
    windowWidth: innerWidth,
    option: {
      needleValue: 0,
      arcDelimiters: [3, 10],
      arcColors: ["rgb(54, 133, 30)", "rgb(214, 207, 17)", "rgb(140, 55, 55)"],
      arcLabels: ["3", "10"],
      innerNeedle: true,
      centralLabel: "0"
    }
  }),
  components: {
    VueGauge
  },
  watch: {
    url() {
      if (this.url) {
        this.loadIndicateurs(this.url)
        this.loaded = false
        // console.log("gauge log")
      }
    }
  },
  created(){
    if (this.windowWidth > 1341) {
      this.option.chartWidth= Math.round(Number(innerWidth * 0.16)*100)/100
    }else{
      if (this.windowWidth <= 1341 && this.windowWidth > 932) {
        this.option.chartWidth= Math.round(Number(innerWidth * 0.152)*100)/100
      }else{
        if (this.windowWidth <= 932) {
          this.option.chartWidth= Math.round(Number(innerWidth * 0.135)*100)/100
        }
      }
    }
    this.loadIndicateurs(this.url)

  },
  computed: {},
  methods: {
    loadIndicateurs(data) {
      var query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
      HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/taux-maitrise-charges-fonctionnement-siege" + query)
        .then(response => {
          this.option.needleValue = Number(response.data.valeur)
          this.option.centralLabel = Math.round(Number(response.data.valeur) * 100) / 100
          this.option.centralLabel = this.option.centralLabel.toString()
          // console.log("gauge option")
          // console.log(this.option)
          this.loaded = true
        })
        .catch(errors => {
          console.log(errors)
        })
    }
  },
  props: ["id", "url"]
}
</script>
