<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Indicateurs</span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Indicateurs financiers
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pt-3 pb-3">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="year.annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="year.annee_fin"/>
        </div>
        <!-- <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="chargeGraph()">
            Rechercher
          </button>
        </div> -->
      </form>
      <!-- <div class="col-3">
        <form class="form-inline">
          <label class="my-1 mr-2">Sélectionner l'année</label>
          <select class="custom-select my-1 mr-sm-2"
                  v-model="year">
            <option selected
                    value="-1">Année</option>
            <option :value="year - 5">{{ year - 5 }}</option>
            <option :value="year - 4">{{ year - 4 }}</option>
            <option :value="year - 3">{{ year - 3 }}</option>
            <option :value="year - 2">{{ year - 2 }}</option>
            <option :value="year - 1">{{ year - 1 }}</option>
            <option :value="year">{{ year }}</option>
            <option :value="year + 1">{{ year + 1 }}</option>
            <option :value="year + 2">{{ year + 2 }}</option>
            <option :value="year + 3">{{ year + 3 }}</option>
            <option :value="year + 4">{{ year + 4 }}</option>
            <option :value="year + 5">{{ year + 5 }}</option>
          </select>
        </form>
      </div> -->
    </div>
    <div style="background: rgba(218, 227, 234, 0.46);"
         class="p-3">
      <h1 class="af-title pt-2 pb-2">Finance</h1>
      <div class="row row-cols-1 row-cols-md-5">
        <div class=" col mb-4">
          <div class="gauge_col card">
            <div class="card-body px-0">
              <GaugeEfdr :id="'tefddrec'"
                         :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[0].title }" />
              <p class="af-text pt-3"
                 data-bs-toggle="tooltip"
                 data-bs-placement="left"
                 title="[ (Décomptes payés / Décomptes reçus sur l’exercice en cours) x 100 ]">
                Taux d'Exécution Financière des Décomptes Reçus
              </p>
            </div>
          </div>
        </div>
        <div class=" col mb-4">
          <div class="gauge_col card">
            <div class="card-body px-0">
              <GaugeEfda :id="'tarp'"
                         :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[1].title }" />
              <p class="af-text pt-3"
                 data-bs-toggle="tooltip"
                 data-bs-placement="right"
                 title="[ (Décomptes des programmes antérieurs payés / Stock des décomptes antérieurs existant) x 100 ]">
                Taux d'Exécution Financière des Décomptes Antérieurs
              </p>
            </div>
          </div>
        </div>
        <div class=" col mb-4">
          <div class="gauge_col card">
            <div class="card-body px-0">
              <GaugeCdb :id="'tcdb'"
                        :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[2].title }" />
              <p class="af-text pt-3"
                 data-bs-toggle="tooltip"
                 data-bs-placement="top"
                 title="[ ((Recettes année(n)-Recettes année(n-1))x100) / Recettes année(n-1) ]">
                Taux d'Accroissement des Recettes de Péage
              </p>
            </div>
          </div>
        </div>
        <div class=" col mb-4">
          <div class="gauge_col card">
            <div class="card-body px-0">
              <GaugeCb :id="'tefdrec'"
                       :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[3].title }" />
              <p class="af-text pt-3"
                 data-bs-toggle="tooltip"
                 data-bs-placement="top"
                 title="[(Décomptes payés sur l’exercice / Besoins financiers de l’entretien routier) x 100 ]">
                Taux de Couverture des Besoins
              </p>
            </div>
          </div>
        </div>
        <div class=" col mb-4">
          <div class="gauge_col card">
            <div class="card-body px-0">
              <GaugeMcfs :id="'tmcfs'"
                         :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[4].title }" />
              <p class="af-text pt-3"
                 data-bs-toggle="tooltip"
                 data-bs-placement="left"
                 title="[ ((Charges de fonctionnement / Ressources mobilisées hors levée de fonds et recette de péage et pesage) X 100 ]">
                Taux de Maitrise des Charges de Fonctionnement du SIEGE
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-md-7">
        <div style="background: rgba(218, 227, 234, 0.46);"
             class="p-3">
          <h1 class="af-title pt-2 pb-2">Trésorerie</h1>
          <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-4">
              <div class="gauge_col card">
                <div class="card-body px-0">
                  <GaugeCefp :id="'tcefp'"
                             :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[5].title }" />
                  <p class="af-text pt-3"
                     data-bs-toggle="tooltip"
                     data-bs-placement="right"
                     title="[ (Provisions constituées / Engagements prévues) x 100 ]">
                    Taux de Couverture des Engagements Financiers sur une Période
                  </p>
                </div>
              </div>
            </div>
            <div class="col mb-4">
              <div class="gauge_col card">
                <div class="card-body px-0">
                  <GaugeAf :id="'taf'"
                           :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[6].title }" />
                  <p class="af-text pt-3"
                     data-bs-toggle="tooltip"
                     data-bs-placement="top"
                     title="[ (Capitaux propres / Endettement net) x 100 ]">
                    Taux d’Autonomie Financière
                  </p>
                </div>
              </div>
            </div>
            <div class="col mb-4 justify-content-center">
              <div class="gauge_col card">
                <div class="card-body px-0">
                  <GaugeL :id="'tl'"
                          :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[7].title }" />
                  <p class="af-text pt-3"
                     data-bs-toggle="tooltip"
                     data-bs-placement="top"
                     title="[ (Actif circulant / Passif circulant) x 100 ]">
                    Taux de Liquidité 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div style="background: rgba(218, 227, 234, 0.46);"
             class="p-3">
          <h1 class="af-title pt-2 pb-2">Contrôle de gestion</h1>
          <div class="row row-cols-1 row-cols-md-2">
            <div class="col mb-4">
              <div class="gauge_col_min card">
                <div class="card-body px-0">
                  <GaugeMra :id="'tafsp'"
                            :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[9].title }" />
                  <p class="af-text pt-3"
                     data-bs-toggle="tooltip"
                     data-bs-placement="left"
                     title="[ (Ressources mobilisées/Ressources prévues) X 100 ]">
                    Taux de Mobilisation des Resources Affectées
                  </p>
                </div>
              </div>
            </div>
            <div class="col mb-4">
              <div class="gauge_col_min card">
                <div class="card-body px-0">
                  <GaugeMr :id="'tcefsp'"
                           :url="{ annee_debut: year.annee_debut, annee_fin: year.annee_fin, type: listIndicateurs[8].title }" />
                  <p class="af-text pt-3"
                     data-bs-toggle="tooltip"
                     data-bs-placement="right"
                     title="[ (Ressources affectées mobilisées/Budget cible) X 100 ]">
                    Taux de mobilisation des resources
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap"
// import Gauge from "@/components/shared/Speedometer"
import GaugeEfdr from "./indicateurs/gauge0"
import GaugeEfda from "./indicateurs/gauge1"
import GaugeCdb from "./indicateurs/gauge2"
import GaugeCb from "./indicateurs/gauge3"
import GaugeMcfs from "./indicateurs/gauge4"
import GaugeCefp from "./indicateurs/gauge5"
import GaugeAf from "./indicateurs/gauge6"
import GaugeL from "./indicateurs/gauge7"
import GaugeMr from "./indicateurs/gauge8"
import GaugeMra from "./indicateurs/gauge9"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "Indicateurs",
  components: {
    GaugeEfdr,
    GaugeEfda,
    GaugeCdb,
    GaugeCb,
    GaugeMcfs,
    GaugeCefp,
    GaugeAf,
    GaugeL,
    GaugeMr,
    GaugeMra
    // Gauge
  },
  data() {
    return {
      year: {
        annee_debut: new Date().getFullYear()+"-01-01",
        annee_fin: new Date().getFullYear()+"-12-31",
      },
      listIndicateurs: [
        {
          title: "i_taux_execution_financiere_decomptes_recu2"
        },
        {
          title: "i_taux_execution_financiere_decomptes_anterieurs2"
        },
        {
          title: "i_taux_accroissement_recette_peage2"
        },
        {
          title: "i_taux_couverture_besoins2"
        },
        {
          title: "i_taux_maitrise_charges_fonctionnement_siege2"
        },
        {
          title: "i_taux_couverture_engagements_financiers2"
        },
        {
          title: "i_taux_autonomie_financiere2"
        },
        {
          title: "i_taux_liquidite2"
        },
        {
          title: "i_taux_mobilisation_ressources2"
        },
        {
          title: "i_taux_mobilisation_ressources_affectees2"
        }
      ]
    }
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters(["indicateurs", "inderrors"])
  },
  methods: {
    ...mapActions(["loadIndicateurs"])
  },

  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  }
}
</script>

<style>
@import "./operationsBancaires.css";
</style>

<style scoped>
.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #0097a9;
  text-transform: uppercase;
}

.title-card {
  background: rgba(229, 229, 229, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.af-btn {
  background: #8cc63e;
  border-radius: 10px;
  height: 66px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #016677;
}

.af-btn-group {
  padding-top: 20px;
}

.af-disabled-btn {
  height: 66px;
  left: 908px;
  top: 144px;
  background: rgba(229, 229, 229, 0.5);
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: rgba(128, 128, 128, 0.5);
}

.af-card-header {
  background: #0097a9;
  height: 58px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.af-bg {
  background: #c4c4c4;
}

.af-title {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.15em;
  color: #016677;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}

.af-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}
form.form-inline input,
form.form-inline label,
form.form-inline button{
  font-size: 0.6em;
}
form.form-inline input{
  height: fit-content;
}
</style>
